@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-SemiBold.ttf') format('truetype');
  font-style: 700;
}

@font-face {
  font-family: 'LatoOriginal';
  src: url('./assets/fonts/Lato2/Lato.ttf') format('truetype');
  font-weight: 400;
}

html {
  overflow: scroll;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

iframe {
  display: none;
}

.swiper {
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.swiper-button-next {
  left: unset !important;
  right: 20px !important; 
  width: 50px !important;
  height: 50px !important;
  transform: rotate(90deg);
  background-image: url('./assets/image/returnButton.svg');
  background-repeat: no-repeat;
  background-size: 50px !important;
  z-index: 9999 !important;
}

.swiper-button-next::after {
  opacity: 0;
}

.swiper-button-prev::after {
  opacity: 0;
}

.swiper-button-prev {
  left: 20px !important;
  right: unset !important;
  width: 50px !important;
  height: 50px !important;
  transform: rotate(270deg);
  background-image: url('./assets/image/returnButton.svg');
  background-repeat: no-repeat;
  background-size: 50px !important;
  z-index: 9999 !important;
}